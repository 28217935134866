import React from "react"

import Layout from "../components/Layout"
import TitleBlock from "../components/TitleBlock"

import "../styles/global.scss"
import styles from "./qrcode.module.scss"

import useTranslation from "../hooks/useTranslation"

const PageQRCode = () => {
  const { t } = useTranslation("qrCode")

  return (
    <Layout>
      <TitleBlock className={styles.mainBox} miniTitle="" title={t("title")}>
        {t("text")}
      </TitleBlock>
    </Layout>
  )
}

export default PageQRCode
